@import "../bower_components/normalize.css/normalize";
@import "bootstrap/bootstrap";

.row{
  &:after, &:before{
    display: inline-block;
  }
}

.clear{
  clear: both;
}
@font-face {
  font-family: helvetica-t;
  src: url(/fonts/HelveticaNeueCyr-Thin.otf) format("opentype");
}
@font-face {
  font-family: helvetica-l;
  src: url(/fonts/HelveticaNeueCyr-Light.otf) format("opentype");
}
@font-face {
  font-family: helvetica-r;
  src: url(/fonts/HelveticaNeueCyr-Roman.otf) format("opentype");
}
@font-face {
  font-family: helvetica-m;
  src: url(/fonts/HelveticaNeueCyr-Medium.otf) format("opentype");
}
@font-face {
  font-family: helvetica-b;
  src: url(/fonts/HelveticaNeueCyr-Bold.otf) format("opentype");
}
@font-face {
  font-family: helvetica-bb;
  src: url(/fonts/HelveticaNeueCyr-Black.otf) format("opentype");
}
*{
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-family: helvetica-r;
}
a,button{
  &:hover{
    text-decoration: none;
  }
}
input:focus{
  &::placeholder{
    opacity: 0;
  }
}
button:focus,button:active,input:focus,input:active{
  outline: none!important;
}
.slick-slide:focus{
  outline: none!important;
}
.container{
  max-width: 1400px;
  padding-left: 30px;
  padding-right: 30px;
}
button.slick-arrow{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  opacity: 0;
}
body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background: #ccc;
}
body::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #1F3171;
  -webkit-box-shadow: 0 0 1px #ccc;
}
header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 6;
  .container{
    position: relative;
    padding-top: 15px;
    transition: .2s linear;
    padding-bottom: 15px;
    a.logo{
      display: table;
      width: 100%;
      margin: 0;
      img{
      }
    }
    ul.menu{
      display: flex;
      width: 100%;
      margin: 18px 0 0;
      li{
        display: inline-flex;
        flex-grow: 1;
        a{
          display: table;
          width: 100%;
          text-align: center;
          color: #333;
          font-family: helvetica-l;
        }
      }
    }
    a.phone{
      display: inline-table;
      vertical-align: middle;
      color: #333;
      font-family: helvetica-m;
      font-size: 16px;
      margin: 18px 0 0;
    }
    a.callback{
      display: inline-table;
      float: right;
      vertical-align: middle;
      padding: 10px 20px;
      color: #fff;
      margin: 8px 0 0;
      background: #1F3171;
    }
  }
  &.fixed{
    .container{
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}
.slideBlock{
  position: relative;
  background: url("/img/slide.png")no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: table;
  width: 100%;
  .verticalBlock{
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    .container{
      h1{
        display: table;
        color: #fff;
        font-family: helvetica-b;
        font-size: 44px;
        margin: 0 0 16px;
      }
      p.descr{
        display: table;
        margin: 0 0 35px;
        color: #fff;
        font-family: helvetica-m;
        font-size: 20px;
        line-height: 1.5;
        width: 40%;
      }
      p.miniName{
        display: table;
        color: #fff;
        font-family: helvetica-b;
        font-size: 24px;
        margin: 0 0 45px;
      }
      a{
        display: table;
        background: transparent;
        color: #fff;
        border: 1px solid #fff;
        padding: 13px 25px;
        transition: .2s linear;
        font-size: 16px;
        &:hover{
          background: #fff;
          color: #1F3171;
        }
      }
    }
  }
}

.aboutBlock{
  position: relative;
  .container{
    padding-top: 100px;
    padding-bottom: 100px;
    img{
      display: table;
      width: 75%;
    }
    p.blue{
      display: table;
      margin: 40px 0 10px;
      color: #2B6BF3;
      font-family: helvetica-l;
    }
    h2{
      display: table;
      margin: 0 0 45px;
      position: relative;
      color: #293553;
      font-family: helvetica-b;
      &:before{
        content: '';
        position: absolute;
        bottom: -20px;
        width: 200px;
        left: 0;
        border-bottom: 1px solid #edeeef;
      }
    }
    p.black{
      display: table;
      color: #333;
      margin: 0 0 15px;
      line-height: 1.6;
      font-family: helvetica-r;
    }
    p.text{
      display: table;
      margin: 0;
      line-height: 1.6;
      color: #787878;
      font-family: helvetica-l;
    }
  }
}
.doverBlock{
  position: relative;
  overflow: hidden;
  .container{
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: -5%;
      width: 110%;
      height: 100%;
      background: #f9f9f9;
    }
    p.blue{
      display: table;
      margin: 0 0 10px;
      color: #2B6BF3;
      font-family: helvetica-l;
    }
    h2{
      display: table;
      margin: 0 0 45px;
      position: relative;
      color: #293553;
      font-family: helvetica-b;
      &:before{
        content: '';
        position: absolute;
        bottom: -22.5px;
        width: 200px;
        left: 0;
        border-bottom: 1px solid #edeeef;
      }
    }
    p.black{
      display: table;
      color: #333;
      margin: 0 0 35px;
      line-height: 1.6;
      font-family: helvetica-m;
    }
    .block{
      display: table;
      width: 100%;
      position: relative;
      background: #fff;
      transition: .2s linear;
      border: none;
      padding: 30px 45px;
      opacity: 0.7;
      margin-bottom: 20px;
      &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-left: 4px solid #293553;
      }
      p.num{
        display: inline-table;
        font-size: 56px;
        font-family: helvetica-m;
        color: #293553;
        margin: 0 45px 0 0;
        vertical-align: middle;
      }
      .content{
        display: inline-table;
        width: 80%;
        vertical-align: middle;
        p.name{
          display: table;
          margin: 0 0 5px;
          font-family: helvetica-m;
          color: #333;
        }
        p.descr{
          display: table;
          margin: 0;
          line-height: 1.6;
          color: #787878;
          font-size: 12px;
        }
      }
      &:hover{
        opacity: 1;
        box-shadow: 0px 0.608796px 5.08245px rgba(0, 0, 0, 0.0120799), 0px 1.46302px 12.7396px rgba(0, 0, 0, 0.0127009), 0px 2.75474px 24.7334px rgba(0, 0, 0, 0.0125916), 0px 4.91399px 44.9736px rgba(0, 0, 0, 0.0144412), 0px 9.19107px 84.1815px rgba(0, 0, 0, 0.0220445), 0px 22px 174px rgba(0, 0, 0, 0.07);
      }
    }
    a.callback{
      display: table;
      margin: 20px 0 0;
      padding: 12px 24px;
      color: #fff;
      background: #1F3171;
      &:hover{
        opacity: 0.8;
      }
    }
  }
}

.ourbestBlock{
  position: relative;
  .container{
    padding-top: 100px;
    h3{
      display: table;
      margin: 0 0 60px;
      position: relative;
      color: #293553;
      font-size: 32px;
      font-family: helvetica-b;
      &:before{
        content: '';
        position: absolute;
        bottom: -22.5px;
        width: 200px;
        left: 0;
        border-bottom: 1px solid #edeeef;
      }
    }
    .block{
      display: table;
      width: 100%;
      position: relative;
      margin: 20px 0;
      img{
        display: inline-table;
        margin-right: 30px;
        vertical-align: top;
      }
      .content{
        display: inline-table;
        width: 75%;
        vertical-align: top;
        p.name{
          display: table;
          font-family: helvetica-b;
          color: #333;
          margin: 0 0 5px;
          font-size: 16px;
        }
        p.descr{
          display: table;
          line-height: 1.4;
          color: #737373;
        }
      }
    }
    .line{
      display: table;
      width: 100%;
      margin: 50px 0 0;
      border-bottom: 2px solid #eee;
    }
  }
}

.servicesBlock{
  position: relative;
  .container{
    padding-top: 100px;
    padding-bottom: 100px;
    p.blue{
      display: table;
      margin: 0 auto 10px;
      color: #2B6BF3;
      font-family: helvetica-l;
    }
    h3{
      display: table;
      margin: 0 auto 45px;
      font-size: 32px;
      position: relative;
      color: #293553;
      font-family: helvetica-b;
      &:before{
        content: '';
        position: absolute;
        bottom: -22.5px;
        width: 200px;
        left: 50%;
        margin-left: -100px;
        border-bottom: 1px solid #edeeef;
      }
    }
    p.black{
      display: table;
      color: #333;
      margin: 0 auto 50px;
      width: 30%;
      line-height: 1.6;
      text-align: center;
      font-family: helvetica-m;
    }
    .block{
      display: table;
      width: 100%;
      color: #fff;
      background: #1E244F;
      p.name{
        padding: 20px 30px;
        display: table;
        margin: 0;
        font-family: helvetica-b;
        font-size: 16px;
        span{
          display: table;
          margin: 5px 0 0;
          font-size: 14px;
          font-family: helvetica-l;
        }
      }
      .content{
        background: #171C41;
        padding: 20px 30px;
        transition: .2s linear;
        height: 170px;
        p{
          font-family: helvetica-t;
          font-size: 13px;
          line-height: 1.6;
          span{
            font-family: helvetica-m;
            font-size: 13px;
          }
          &.second{
            margin: 0;
            span{
              display: table;
            }
          }
        }
      }
      p.miniName{
        display: table;
        padding: 20px 30px;
        font-family: helvetica-b;
        font-size: 16px;
        margin: 0;
      }
      a{
        display: table;
        padding: 10px 45px;
        color: #000;
        background: #F3F3F3;
        margin: 0 30px 20px;
        transition: .2s linear;
        font-family: helvetica-m;
      }
      &:hover{
        background: #1B1E34;
        .content{
          background: #16192D;
        }
        a{
          color: #fff;
          background: #2B6BF3;
        }
      }
    }
  }
}
.stepsBlock{
  position: relative;
  padding-bottom: 120px;
  .container{
    position: relative;
    padding-top: 80px;
    padding-bottom: 40px;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: -5%;
      width: 110%;
      height: 100%;
      background: url("/img/bkg2.png")no-repeat;
      background-size: cover;
      background-position: center;
    }
    p.mini{
      display: table;
      margin: 0 0 10px;
      color: #fff;
      font-family: helvetica-l;
    }
    h3{
      display: table;
      margin: 0 0 50px;
      font-size: 32px;
      position: relative;
      color: #fff;
      font-family: helvetica-b;
      &:before{
        content: '';
        position: absolute;
        bottom: -20px;
        opacity: 0.2;
        width: 200px;
        left: 0;
        border-bottom: 1px solid #edeeef;
      }
    }
    p.descr{
      display: table;
      line-height: 1.6;
      color: #fff;
      font-family: helvetica-t;
      margin: -15px 0 0;
      letter-spacing: 1px;
      width: 90%;
    }
    .block{
      display: table;
      width: 100%;
      position: relative;
      background: #172F5A;
      height: 180px;
      padding: 0 35px 0;
      margin-bottom: 30px;
      p.num{
        position: absolute;
        top: 30px;
        left: 35px;
        font-size: 72px;
        font-family: helvetica-bb;
        color: #fff;
        opacity: 0.1;
      }
      p.name{
        display: table;
        color: #fff;
        line-height: 1.6;
        margin: 78px 0 30px;
      }
    }
    .callback{
      display: table;
      padding: 12px 50px;
      background: #fff;
      color: #333;
      font-family: helvetica-b;
      margin: 20px auto;
    }
  }
}

.callbackBlock{
  background: #0e2245;
  position: relative;
  &:after{
    position: absolute;
    content: '';
    width: 100%;
    right: 0;
    top: 0;
    height: 100%;
    background: url("/img/bkg3.png")no-repeat;
    background-size: cover;
    background-position: left top;
  }
  .container{
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    z-index: 2;
    h4{
      display: table;
      margin: 0 0 40px;
      font-size: 32px;
      position: relative;
      color: #fff;
      font-family: helvetica-b;
      &:before{
        content: '';
        position: absolute;
        bottom: -20px;
        opacity: 0.2;
        width: 200px;
        left: 0;
        border-bottom: 1px solid #edeeef;
      }
    }
    p.descr{
      display: table;
      color: #fff;
      margin: 0 0 25px;
      width: 30%;
      line-height: 1.6;
      text-align: left;
      font-family: helvetica-l;
    }
    form{
      display: table;
      width: 50%;
      input{
        display: inline-table;
        padding: 12px 15px;
        border: 1px solid rgba(255, 255, 255, 0.17);
        background: transparent;
        color: #fff;
        margin-right: 20px;
        width: 45%;
        margin-bottom: 20px;
        &::placeholder{
          color: rgba(255, 255, 255, 0.8);
        }
      }
      button{
        display: table;
        padding: 12px 0;
        border: none;
        text-align: center;
        width: 45%;
        color: #333;
        background: #fff;
        transition: .2s linear;
        &:hover{
          opacity: 0.5;
        }
      }
    }
  }
}

.partnersBlock{
  position: relative;
  .container{
    padding-top: 100px;
    padding-bottom: 100px;
    p.blue{
      display: table;
      margin: 0 auto 10px;
      color: #2B6BF3;
      font-family: helvetica-l;
    }
    h4{
      display: table;
      margin: 0 auto 45px;
      font-size: 32px;
      position: relative;
      color: #293553;
      font-family: helvetica-b;
    }
    ul.partnersList{
      display: flex;
      width: 100%;
      margin: 40px 0 0;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      li{
        display: inline-flex;
        flex-grow: 1;
        transition: .2s linear;
        padding: 40px 0;
        img{
          display: table;
          margin: 0 auto;
          height: 80px;
          width: 140px;
          object-fit: contain;
        }
        &:hover{
          box-shadow: 0 5px 25px #eee;
        }
      }
      &.second{
        margin: 0;
        border-top: none;
      }
    }
  }
}

.messagesBlock{
  position: relative;
  .container{
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      width: 110%;
      left: -5%;
      top: 0;
      height: 100%;
      background: #f9f9f9;
    }
    p.blue{
      display: table;
      margin: 0 0 10px;
      color: #2B6BF3;
      font-family: helvetica-l;
    }
    h4{
      display: table;
      margin: 0 0 45px;
      font-size: 32px;
      position: relative;
      color: #293553;
      font-family: helvetica-b;
      &:before{
        content: '';
        position: absolute;
        bottom: -22.5px;
        width: 200px;
        left: 0;
        border-bottom: 1px solid #e1e1e1;
      }
    }
    p.descr{
      display: table;
      width: 80%;
      line-height: 1.8;
      color: #757575;
      font-family: helvetica-l;
    }
    .pagination{
      display: table;
      margin: 40px 0 25px;
      ul{
        display: table;
        margin: 0;
        li{
          display: inline-table;
          margin: 0 12px 0 0;
          button{
            display: table;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: none;
            transition: .2s linear;
            background: #C4C4C4;
            font-size: 0;
            color: transparent;
          }
          &.slick-active{
            button{
              background: #293553;
            }
          }
        }
      }
    }

    .messageSlick{
      width: 100%;
      margin-top: 50px;
      .slick-slide{
        .block{
          display: table;
          width: 95%;
          position: relative;
          padding-top: 40px;
          &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            background: url("/img/icon.svg")no-repeat;
            background-size: contain;
          }
          img{
            display: table;
            height: 30px;
            margin: 0 0 20px;
          }
          p.name{
            font-family: helvetica-b;
            color: #5b5b5b;
            margin-bottom: 10px;
            font-size: 16px;
            span{
              display: table;
              color: #A8A8A8;
              margin: 5px 0 0;
              font-family: helvetica-l;
              font-size: 16px;
            }
          }
          p.descrMessage{
            display: block;
            line-height: 1.8;
            color: #757575;
            font-family: helvetica-l;
            height: 100px;
            overflow: hidden;
            margin: 0;
          }
        }
      }
    }
    a.arrows{
      position: absolute;
      bottom: 40%;
      height: 40px;
      margin-top: -40px;
      padding: 10px 0;
      img{
        width: 60px;
      }
      &.left{
        left: -70px;
        img{
          transform: rotate(180deg);
        }
      }
      &.right{
        right: -40px;
      }
      &:hover{
        cursor: pointer;
      }
    }
  }
}

.contactsBlock{
  position: relative;
  .container{
    padding-top: 100px;
    padding-bottom: 100px;
    p.blue{
      display: table;
      margin: 0 0 10px;
      color: #2B6BF3;
      font-family: helvetica-l;
    }
    h4{
      display: table;
      margin: 0 0 40px;
      font-size: 32px;
      position: relative;
      color: #293553;
      font-family: helvetica-b;
      &:before{
        content: '';
        position: absolute;
        bottom: -20px;
        width: 200px;
        left: 0;
        border-bottom: 1px solid #e1e1e1;
      }
    }
    p.black{
      display: table;
      color: #333;
      margin: 0;
      width: 80%;
      line-height: 1.6;
      text-align: left;
      font-family: helvetica-m;
    }
    p.name{
      display: table;
      font-family: helvetica-m;
      margin-bottom: 10px;
      margin-top: 20px;
    }
    ul{
      display: table;
      margin: 0;
      li{
        display: table;
        margin-bottom: 5px;
      }
      p{
        display: table;
        font-family: helvetica-m;
        color: #777;
        margin: 0;
      }
      a{
        display: table;
        color: #777;
        margin: 0;
      }
    }
    .map{
      display: table;
      width: 100%;
      background: #eee;
      height: 350px;
      margin: 70px 0 0;
    }
  }
}
footer{
  background: #202941;
  .container{
    padding-top: 30px;
    padding-bottom: 30px;
    ul.menu{
      display: flex;
      width: 100%;
      margin: 18px 0 0;
      li{
        display: inline-flex;
        flex-grow: 1;
        a{
          display: table;
          width: 100%;
          text-align: center;
          color: #fff;
          font-family: helvetica-l;
        }
      }
    }
    a.phone{
      display: inline-table;
      vertical-align: middle;
      color: #fff;
      font-family: helvetica-m;
      font-size: 16px;
      margin: 18px 0 0;
    }
    a.callback{
      display: inline-table;
      float: right;
      vertical-align: middle;
      padding: 10px 20px;
      color: #1F3171;
      margin: 8px 0 0;
      background: #fff;
    }
  }
  .copyr{
    background: #fff;
    width: 100%;
    padding: 12px 0;
    a{
      display: table;
      margin: 0 auto;
      font-family: helvetica-l;
      color: #666;
    }
  }
}
.modal-content {
  border-radius: 2px;
  border: 1px solid #fff;
  padding: 20px 35px;
}

.modal-content .close {
  position: absolute;
  right: 25px;
  top: 15px;
}

.modal-content .bigName {display: table;margin: 15px 0 10px;font-family: helvetica-b;font-size: 22px;}

.modal-content .miniDescr {
  display: table;
  line-height: 1.6;
  font-size: 12px;
  color: #777;
}

.modal-content form {
  display: table;
  width: 100%;
}

.modal-content form input {
  display: inline-table;
  width: 45%;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid #eee;
  padding: 10px 15px;
}

.modal-content form button {
  display: table;
  padding: 10px 0;
  text-align: center;
  width: 45%;
  border: none;
  background: #1f3171;
  color: #fff;
  transition: .2s linear;
}

.modal-content form button:hover {
  opacity: 0.6;
}
.modal-dialog {
  margin-top: 10%;
}

.inPage{
  position: relative;
  padding-top: 180px ;
  .blockService{
    position: relative;
    padding: 60px 120px;
    background: #F9F9F9;
    margin-bottom: 80px;
    transition: .2s linear;
    &:hover{
      box-shadow: 0 8px 28px #ccc;
    }
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 3px;
      background: #293553;
    }
    &:after{
      bottom: -40px;
      left: 0;
      width: 100%;
      content: '';
      position: absolute;
      height: 1px;
      background: #141D25;
      opacity: 0.1;
    }
    &.lastBlock{
      &:after{
      display: none;
      }
    }
    img{
      display: table;
      margin: 0;
      width: 70%;
      height: 350px;
      object-fit: cover;
    }
    p.blue{
      display: table;
      margin: 0 0 10px;
      color: #2B6BF3;
      font-family: helvetica-l;
    }
    p.name{
      display: table;
      margin: 0 0 45px;
      position: relative;
      font-size: 32px;
      color: #293553;
      line-height: 1.2;
      font-family: helvetica-b;
      &:before{
        content: '';
        position: absolute;
        bottom: -20px;
        width: 200px;
        left: 0;
        border-bottom: 1px solid #edeeef;
      }
    }
    p.descr{
      display: table;
      color: #333;
      margin: 0 0 15px;
      line-height: 1.6;
      font-family: helvetica-r;
      width: 70%;
    }
    ul.buttons{
      display: table;
      margin: 40px 0 0;
      li{
        display: inline-table;
        margin-right: 25px;
        &:first-child{
        a{
          display: table;
          padding: 8px 35px;
          background: #2B6BF3;
          color: #fff;
          font-family: helvetica-m;
        }
        }
        &:last-child{
        a{
          display: table;
          padding: 8px 35px;
          background: #F3F3F3;
          color: #333;
          font-family: helvetica-m;
        }
        }
      }
    }
  }
}
div.serviceModal{
  padding: 0 30px;
  .modal-dialog {
  max-width: 1400px;
    margin-top: 5%;
    .close{
      z-index: 15;
    }
    p.name{
      display: table;
      margin: 0 0 45px;
      position: relative;
      font-size: 28px;
      color: #293553;
      line-height: 1.2;
      font-family: helvetica-b;
      &:before{
        content: '';
        position: absolute;
        bottom: -20px;
        width: 200px;
        left: 0;
        border-bottom: 1px solid #edeeef;
      }
    }
    p.black{
      display: table;
      color: #333;
      margin: 0 0 35px;
      line-height: 1.6;
      font-family: helvetica-m;
      width: 90%;
      &.secondary{
        margin: 30px 0 18px;
      }
    }
    p.descr{
      display: table;
      margin: 0 0 15px;
      line-height: 1.6;
      color: #787878;
      font-family: helvetica-l;
      width: 80%;
    }
    p.imgText{
      display: table;
      padding-left: 40px;
      position: relative;
      margin: 0 0 20px;
      line-height: 1.6;
      width: 70%;
      color: #787878;
      img{
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
    .imgBlock{
      display: table;
      position: absolute;
      right: 40px;
      top: 35%;
      width: 200px;
      img{
        display: table;
        width: 100%;
        object-fit: cover;
        height: 280px;
        margin: 0 0 15px;
      }
      p{
        display: table;
        color: #777;
        font-family: helvetica-l;
        font-size: 13px;
        a{
          display: table;
          color: #2B6BF3;
          border-bottom: 1px dashed;
          margin-top: 5px;
        }
      }
    }
}
}
@media(max-width: 1600px){
  .doverBlock {
    overflow: hidden;
  }

  .stepsBlock {
    overflow: hidden;
  }

  .messagesBlock {
    overflow: hidden;
  }
}
@media(max-width: 1199px){
  .messagesBlock {
    overflow: hidden;
  }

  footer {
    overflow: hidden;
  }

  .callbackBlock {
    overflow: hidden;
  }

  .stepsBlock {
    overflow: hidden;
  }

  header .container ul.menu {
    display: table;
    margin: 5px auto 0;
    width: 78%;
    float: right;
  }

  header .container ul.menu li {
    display: inline-table;
    margin-right: 25px;
    margin-bottom: 10px;
  }

  header .container a.phone {
    margin: 0;
    float: right;
    font-size: 14px;
  }

  header .container a.callback {
    padding: 5px 20px;
  }

  .slideBlock {
    height: auto;
  }

  .slideBlock .verticalBlock {
    padding: 180px 0 100px;
  }

  .aboutBlock .container {
    padding: 60px 15px;
  }

  .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  header {
    padding-right: 15px;
    padding-left: 15px;
  }

  .aboutBlock .container p.blue {
    margin: 0 0 10px;
  }

  .aboutBlock .container h2 {
    font-size: 22px;
  }

  .doverBlock .container {
    padding: 60px 15px;
  }

  .doverBlock .container .block {
    padding: 25px 20px;
  }

  .doverBlock .container .block p.num {
    margin-right: 19px;
  }

  .ourbestBlock {
  }

  .ourbestBlock .container {
    padding: 60px 15px 0;
  }

  .ourbestBlock .container h3 {
    font-size: 24px;
  }

  .ourbestBlock .container .block img {
    height: 40px;
  }

  .ourbestBlock .container .block .content p.descr {
    font-size: 12px;
  }

  .servicesBlock .container {
    padding: 60px 15px;
  }

  .servicesBlock .container p.black {
    width: 50%;
  }

  .servicesBlock .container .block p.miniName {
    font-size: 14px;
  }

  .servicesBlock .container .block .content {
    height: 200px;
  }

  .stepsBlock .container {
    padding: 60px 15px;
  }

  .stepsBlock .container h3 {
    font-size: 24px;
  }

  .stepsBlock .container p.descr {
    font-size: 12px;
  }

  .stepsBlock .container .block {
    padding: 0 15px;
  }

  .stepsBlock .container .block p.name {
    font-size: 12px;
  }

  .stepsBlock {
    padding-bottom: 0;
  }

  .callbackBlock .container {
    padding: 50px 15px;
  }

  .callbackBlock:after {
    background-position: right top;
  }

  .partnersBlock .container {
    padding: 60px 15px;
  }

  .partnersBlock .container h4 {
    margin-bottom: 10px;
  }

  .partnersBlock .container ul.partnersList li img {
    width: 100px;
    height: 50px;
  }

  .messagesBlock .container {
    padding: 60px 15px;
  }

  .messagesBlock .container h4 {
    font-size: 22px;
  }

  .messagesBlock .container .pagination {
    margin: 20px 0 15px;
  }

  .messagesBlock .container .messageSlick .slick-slide .block p.name span {
    font-size: 12px;
  }

  .messagesBlock .container .messageSlick .slick-slide .block p.name {
    font-size: 14px;
  }

  .messagesBlock .container .messageSlick .slick-slide .block p.descrMessage {
    font-size: 12px;
  }

  .messagesBlock .container a.arrows.right {
    right: 0;
  }

  .contactsBlock .container {
    padding: 60px 15px;
  }

  .contactsBlock .container h4 {
    font-size: 26px;
  }

  footer .container ul.menu {
    display: table;
    margin: 5px 0 0;
    float: right;
    width: 85%;
  }

  footer .container ul.menu li {
    display: inline-table;
    margin-right: 25px;
    margin-bottom: 12px;
  }

  footer .container a.phone {
    float: right;
    margin: 0;
  }

  footer .container a.callback {
    padding: 5px 20px;
  }
  .inPage .blockService {
    padding: 60px;
  }

  div.serviceModal .modal-dialog .imgBlock img {
    height: 200px;
  }

  div.serviceModal .modal-dialog .imgBlock {
    width: 120px;
  }
}
@media(max-width: 769px){
  header .container a.logo img {
    height: 40px;
    margin-left: -10px;
    margin-top: 6px;
  }

  header .container ul.menu {
    width: 100%;
  }

  header .container ul.menu li {
    margin-right: 15px;
  }

  .slideBlock .verticalBlock .container p.descr {
    width: 80%;
  }

  .ourbestBlock .container .block img {
    margin-bottom: 20px;
  }

  .servicesBlock .container .block p.name {
    font-size: 14px;
  }

  .servicesBlock .container .block .content {
    height: auto;
  }

  .callbackBlock .container form {
    width: 80%;
  }

  .callbackBlock .container p.descr {
    width: 50%;
  }

  .partnersBlock .container h4 {
    font-size: 24px;
  }

  footer .container ul.menu {
    width: 100%;
    display: none;
  }
  .inPage .blockService p.name {
    font-size: 18px;
  }

  .inPage .blockService p.descr {
    width: 100%;
  }

  .inPage .blockService img {
    width: 90%;
  }

  .inPage .blockService {
    padding: 40px;
  }

  div.serviceModal .modal-dialog p {
    font-size: 12px;
  }

  div.serviceModal .modal-dialog p.imgText {
    width: 100%;
  }

  div.serviceModal .modal-dialog .imgBlock {
    position: relative;
    right: unset;
    top: unset;
    margin: 20px auto 0;
  }
}
@media(max-width: 678px){
  header .container ul.menu {
    display: table;
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    width: 100%;
    padding: 20px 0;
    display: none;
  }

  header .container ul.menu li {
    display: table;
    width: 100%;
    margin: 15px 0;
  }

  header {
    padding: 0;
  }

  .partnersBlock {
    overflow: hidden;
  }

  header .container a.callback {
    display: none;
  }

  header .container a.phone {
    display: none;
  }

  a.d-block.d-sm-none.burger {
    position: absolute;
    top: 23px;
    right: 25px;
    z-index: 10;
    transition: .2s linear;
  }

  header .container a.logo img {
    margin: 0;
  }

  a.d-block.d-sm-none.burger i {
    font-size: 20px;
  }

  header.fixed a.d-block.d-sm-none.burger {
    top: 18px;
  }

  .slideBlock .verticalBlock {
    padding: 100px 0 50px;
  }

  .slideBlock .verticalBlock .container h1 {
    font-size: 24px;
  }

  .slideBlock .verticalBlock .container p.descr {
    font-size: 14px;
    width: 100%;
    margin-bottom: 15px;
  }

  .slideBlock .verticalBlock .container p.miniName {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .slideBlock .verticalBlock .container a {
    font-size: 14px;
    margin: 0;
  }

  .aboutBlock .container {
    padding: 30px 0;
  }

  .slideBlock {
    padding: 0;
  }

  .aboutBlock .container img {
    height: 60px;
    width: auto;
    margin: 0 auto 25px;
  }

  .aboutBlock .container h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .aboutBlock .container h2:before {
    bottom: -15px;
  }

  .aboutBlock .container p.black {
    font-size: 12px;
  }

  .aboutBlock .container p.text {
    font-size: 12px;
  }

  .doverBlock .container {
    padding: 30px 0;
  }

  .doverBlock .container h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .doverBlock .container h2:before {
    bottom: -15px;
  }

  .doverBlock .container p.black {
    margin-bottom: 20px;
  }

  .doverBlock .container .block p.num {
    font-size: 26px;
  }

  .ourbestBlock .container {
    padding: 30px 0;
  }

  .ourbestBlock .container h3 {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .ourbestBlock .container h3:before {
    bottom: -15px;
  }

  .ourbestBlock .container .line {
    margin: 0;
  }

  .servicesBlock .container {
    padding: 0 0 30px;
  }

  .servicesBlock .container h3 {
    font-size: 22px;
  }

  .servicesBlock .container p.black {
    margin-bottom: 30px;
    width: 100%;
    text-align: left;
  }

  .servicesBlock .container .block p.name {
    width: 100%;
    padding: 15px;
  }

  .servicesBlock .container .block .content {
    padding: 15px;
  }

  .servicesBlock .container .block p.miniName {
    padding: 15px;
  }

  .servicesBlock .container .block a {
    margin: 0 15px 20px;
    padding: 6px 25px;
  }

  .servicesBlock .container .block {
    margin-bottom: 15px;
  }

  .stepsBlock .container {
    padding: 30px 0;
  }

  .stepsBlock .container h3 {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .stepsBlock .container h3:before {
    bottom: -15px;
  }

  .stepsBlock .container p.descr {
    margin: 0 0 25px;
  }

  .stepsBlock .container .block {
    height: auto;
    padding: 15px 15px;
    margin-bottom: 15px;
  }

  .stepsBlock .container .block p.name {
    margin: 30px 0;
  }

  .stepsBlock .container .block p.num {
    top: 0;
    right: 15px;
    left: unset;
    font-size: 54px;
  }

  .stepsBlock .container .callback {
    margin: 0 auto;
  }

  .stepsBlock .container:before {
    left: -7%;
    width: 120%;
  }

  .callbackBlock .container {
    padding: 30px 0;
  }

  .callbackBlock .container h4 {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .callbackBlock .container h4:before {
    bottom: -15px;
  }

  .callbackBlock .container p.descr {
    width: 100%;
    font-size: 12px;
  }

  .callbackBlock .container form {
    width: 100%;
  }

  .callbackBlock .container form input {
    border: 1px solid rgba(255, 255, 255, 0.46);
    width: 100%;
    margin: 0 0 12px;
  }

  .callbackBlock .container form button {
    width: 100%;
  }

  .callbackBlock:after {
    background-position: center;
  }

  .partnersBlock .container {
    padding: 30px 0;
  }

  .partnersBlock .container ul.partnersList {
    display: table;
    width: 100%;
    margin: 20px 0 0;
    border-bottom: none;
  }

  .partnersBlock .container ul.partnersList li {
    width: 50%;
    margin: 0;
    border-bottom: 1px solid #eee;
    padding: 25px 0;
  }

  .partnersBlock .container ul.partnersList li img {
    height: 60px;
    width: 50%;
  }

  .messagesBlock .container {
    padding: 30px 0 80px;
  }

  .messagesBlock .container h4 {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .messagesBlock .container h4:before {
    bottom: -15px;
  }

  .messagesBlock .container p.descr {
    font-size: 12px;
    width: 100%;
  }

  .messagesBlock .container .pagination {
    margin: 0;
  }

  .messagesBlock .container .messageSlick {
    margin-top: 25px;
  }

  .messagesBlock .container a.arrows {
    bottom: -60px;
    z-index: 15;
  }

  .messagesBlock .container a.arrows.right {
    right: 25%;
  }

  .messagesBlock .container a.arrows.left {
    left: 25%;
  }

  .contactsBlock .container {
    padding: 30px 0;
  }

  .contactsBlock .container h4 {
    margin-bottom: 30px;
  }

  .contactsBlock .container h4:before {
    bottom: -15px;
  }

  .contactsBlock .container p.black {
    width: 100%;
    font-size: 12px;
  }

  .contactsBlock .container p.name {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .contactsBlock .container .map {
    margin: 20px 0 0;
    height: 200px;
  }

  footer .container {
    padding: 20px 15px;
  }

  footer img.logo {
    height: 40px;
    display: table;
  }

  footer .container a.phone {
    float: unset;
    display: table;
    margin: 15px 0 0;
  }

  footer .container a.callback {
    margin: 15px 0 0;
    float: unset;
  }
  header {
    z-index: 25;
  }
  .modal-content form input {
    margin: 0 0 10px;
    width: 100%;
  }

  .modal-content form button {
    width: 100%;
  }
  .inPage {
    padding: 90px 0 0;
  }

  .inPage .blockService {
    padding: 20px;
  }

  .inPage .blockService img {
    height: 100px;
    width: 100%;
  }

  .inPage .blockService p.blue {
    margin-top: 15px;
  }

  .inPage .blockService p.name {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .inPage .blockService p.name:before {
    bottom: -15px;
  }

  .inPage .blockService ul.buttons li a {
    font-size: 12px;
    padding: 10px 15px!important;
  }

  .inPage .blockService ul.buttons li {
    margin: 0 10px;
  }

  .inPage .blockService ul.buttons {
    text-align: center;
    margin: 25px 0 0;
  }

  div.serviceModal {
    padding: 0;
  }

  div.serviceModal .modal-content {
    padding: 40px 15px 15px;
  }

  div.serviceModal .modal-dialog p.name {
    font-size: 16px;
    margin-bottom: 30px;
  }

  div.serviceModal .modal-dialog p.black {
    width: 100%;
    margin-bottom: 25px;
  }

  div.serviceModal .modal-dialog p.descr {
    width: 100%;
    margin-bottom: 10px;
  }

  div.serviceModal .modal-dialog .imgBlock {
    width: 120px;
  }

  div.serviceModal .modal-dialog .imgBlock img {
    width: 100%;
  }
}